import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';
import { TenantDataService } from '../tenant/tenant-data.service';
import { TenantData } from 'src/app/models/tenant-data.model';

@Injectable({
  providedIn: 'root'
})
export class GenericModalService {
  private widgetAcceptButtonColor = '';

  constructor(private translateService: TranslateService,
    private tenantDataService: TenantDataService
  ) {
    this.getData();
  }

  private getData(){
    this.tenantDataService.getDatosTenantEvent().subscribe({
      next: (res: TenantData) => this.widgetAcceptButtonColor = res.data.personaDigital.interfazUsuario.colorBotonesDinamicos.colorFondo
    })
  }

  showSwalModal(titulo: string, texto: string, icono: SweetAlertIcon, colorIcono?: string, timer?: number,
    barraTimer?: boolean, textoConfirmar?: string, mostrarBotonCancelar?: boolean, textoCancelar?: string,
    mostrarBotonAceptar?: boolean, ancho?: string, colorBoton?: string): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      customClass: {
        confirmButton: 'swalConfirmBtn',
        cancelButton: 'swalCancelBtn'
      },
      title: titulo,
      text: texto,
      icon: icono,
      ...(colorIcono && { iconColor: colorIcono }),
      ...(timer && { timer: timer }),
      ...(barraTimer && { timerProgressBar: barraTimer }),
      ...(textoConfirmar && { confirmButtonText: textoConfirmar }),
      ...(mostrarBotonCancelar && { showCancelButton: mostrarBotonCancelar }),
      ...(textoCancelar && { cancelButtonText: textoCancelar }),
      ...(mostrarBotonAceptar && { showConfirmButton: mostrarBotonAceptar }),
      ...(ancho && { width: ancho }),
      ...(colorBoton && { confirmButtonColor: colorBoton })
    });
  }

  showMaxSizeWarningModal(){
    const title = this.translateService.instant('modal.maxSize.title');
    const text = this.translateService.instant('modal.maxSize.text');
    const acceptText = this.translateService.instant('modal.accept');
    this.showSwalModal(title, text, 'warning', '#0E2359', 3000, true, acceptText);
  }

  showInvalidFormatModal(){
    const title = this.translateService.instant('modal.wrongExtension.title');
    const text = this.translateService.instant('modal.wrongExtension.text');
    const acceptText = this.translateService.instant('modal.accept');
    this.showSwalModal(title, text, 'warning', '#0E2359', 5000, true, acceptText);
  }

  showWidgetModal(): Promise<SweetAlertResult<any>>{
    const title = this.translateService.instant('modal.widget.title');
    const text = this.translateService.instant('modal.widget.text');
    const cancelText = this.translateService.instant('modal.widget.regresar');
    const acceptText = this.translateService.instant('modal.si');
    return this.showSwalModal(title, text, 'warning', '#0E2359', undefined, false, acceptText, true, cancelText, true, '90%', this.widgetAcceptButtonColor);
  }
}
