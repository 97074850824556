import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { AvatarData } from 'src/app/models/avatar-data.model';
import { UrlParams } from 'src/app/models/parameters.model';
import { DatumButtons, StartButtons, TenantData } from 'src/app/models/tenant-data.model';
import { TenantDataService } from 'src/app/services/tenant/tenant-data.service';
import { ColorPickerService } from 'src/app/services/utils/color-picker.service';
import { ShortenUrlService } from 'src/app/services/utils/shorten-url.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { ServiceUnavailableComponent } from '../service-unavailable/service-unavailable.component';
import { MaxSessionsComponent } from '../max-sessions/max-sessions.component';
import { EngineComponent } from '../engine/engine.component';
import { GetActiveSubscriptionsService } from 'src/app/services/tenant/get-active-subscriptions.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private ShortenUrlService: ShortenUrlService,
    private webSocketService: WebSocketService,
    private toastr: ToastrService,
    private tenantDataService: TenantDataService,
    private colorPickerService: ColorPickerService,
    private getActiveSubscriptionsService: GetActiveSubscriptionsService,
    private modalService: BsModalService,
  ) { }

  tenantBlancox = false;
  noAvatar = false;
  isLoading = true;
  dataParams!: UrlParams;
  tenantData!: TenantData;
  serviceUnavailable = false;
  modalServiceUnavailable?: BsModalRef;
  modalMaxSessions?: BsModalRef;
  startPressedSubject = new Subject<void | DatumButtons>();
  avoidInitButtonSubject = new Subject<void>();

  @ViewChild(EngineComponent) engineComponent!: EngineComponent;

  ngOnInit(): void {
    this.subscribeRouteParams();
    window.addEventListener('focus', this.onFocus);
  }

  private subscribeRouteParams() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = params.get('id');
      if (id === null) {
        this.startAppWithDefaultParams();
      } else {
        this.subscribeShortenUrl(id);
      }
    });
  }

  private startAppWithDefaultParams = () => {
    this.dataParams = {
      Tenant: 'Innovati',
      Ubicacion: 'web',
      CasoDeUso: 'Demos',
      Flujo: 'todos',
      Canal: 'web',
      IdCasoDeUso: '55e4f189-9535-4940-9bea-dc1980ca0dc7',
      IdFlujo: ''
    };
    this.startAppWithParams();
  }

  startAppWithParams() {
    this.subscribeTenantData();
    this.subscribeUnavailableServiceModals()
    setTimeout(() => {
      this.tenantDataService.queryAllData(this.dataParams)
    }, 500)
  }

  private subscribeTenantData() {
    this.tenantDataService.getDatosTenantEvent().subscribe({
      next: (res: TenantData) => {
        this.setTenantSettings(res);
        this.subscribeAvatarData()
      }, error: (err) => {
        console.error(err);
      }
    })
  }

  private setTenantSettings(res: any) {
    res.data.personaDigital.interfazUsuario.tipoFondo = res.data.personaDigital.interfazUsuario.tipoFondo.toString();
    this.tenantData = res;
    if (this.tenantData.data.personaDigital.interfazUsuario) {
      this.setGlobalStyles();
    }
  }

  setGlobalStyles() {
    const tipoFondo = this.tenantData.data.personaDigital.interfazUsuario.tipoFondo;
    const [backgroundTenant, backgroundColor] = this.getTenantBackground(tipoFondo);

    // Update global styles with the colors of the tenant
    this.setTenantStyles(backgroundTenant, backgroundColor);
  }

  private setTenantStyles(backgroundTenant: string | undefined, backgroundColor: string | undefined) {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      :root {
        --background-image: ${backgroundTenant};
        --background-color: ${backgroundColor};
        --skip-button-bg-color: ${backgroundTenant?.includes('url') ? '#FFFFFF' : backgroundTenant};
        --icon-color: ${this.tenantData.data.personaDigital.interfazUsuario.colorIcono};
        --input-bg-color: ${this.tenantData.data.personaDigital.interfazUsuario.colorInputMensaje.colorFondo};
        --input-text-color: ${this.tenantData.data.personaDigital.interfazUsuario.colorInputMensaje.colorTexto};
        --assitant-buble-bg-color: ${this.tenantData.data.personaDigital.interfazUsuario.colorBurbujaAvatar.colorFondo};
        --assistan-buble-text-color: ${this.tenantData.data.personaDigital.interfazUsuario.colorBurbujaAvatar.colorTexto};
        --assistant-name-color: ${this.colorPickerService.getBlackWhiteColorByContrast(backgroundTenant, this.tenantData.data.personaDigital.interfazUsuario.colorIcono)};
        --user-buble-bg-color: ${this.tenantData.data.personaDigital.interfazUsuario.colorBurbujaUsuario.colorFondo};
        --user-buble-text-color: ${this.tenantData.data.personaDigital.interfazUsuario.colorBurbujaUsuario.colorTexto};
        --dynamic-button-bg-color: ${this.tenantData.data.personaDigital.interfazUsuario.colorBotonesDinamicos.colorFondo};
        --dynamic-button-text-color: ${this.tenantData.data.personaDigital.interfazUsuario.colorBotonesDinamicos.colorTexto};
        --dynamic-button-pressed-bg-color: ${this.tenantData.data.personaDigital.interfazUsuario.colorBotonPresionado.colorFondo};
        --dynamic-button-pressed-text-color: ${this.tenantData.data.personaDigital.interfazUsuario.colorBotonPresionado.colorTexto};
        --font-family: ${this.configureFont()};
      }
    `;
    document.head.appendChild(styleElement);
  }

  private getTenantBackground(tipoFondo: string) {
    let backgroundTenant, backgroundColor;
    if (tipoFondo.toString() === "1") {
      backgroundTenant = `${this.tenantData.data.personaDigital.interfazUsuario.fondo}`;
      backgroundColor = backgroundTenant
    } else if (tipoFondo.toString() === "2") {
      backgroundTenant = `url(${this.tenantData.data.personaDigital.interfazUsuario.fondo})`;
      backgroundColor = this.tenantData.data.personaDigital.interfazUsuario.colorInputMensaje.colorFondo;
    }
    return [backgroundTenant, backgroundColor];
  }

  private subscribeAvatarData() {
    this.tenantDataService.getAvatarDataLocal().subscribe({
      next: (res: AvatarData) => {
        this.setAvatarData(res);
        this.subscribeBotonesInicio()
      }, error: (err) => {
        console.error(err);
      }
    })
  }
  private setAvatarData(res: AvatarData) {
    if (res.data.description.toLocaleLowerCase().includes('buja')) {
      if (res.data.description.toLocaleLowerCase().includes('buja')) {
        this.tenantBlancox = true;
        return;
      }
    }
    if (res.data.engine === "none" || res.data.engine === "null") {
      this.noAvatar = true;
      return;
    }
  }
  private subscribeBotonesInicio = () => {
    this.tenantDataService.getInitialButtonsEvent().subscribe({
      next: (res: StartButtons) => {
        this.webSocketService.setUpWs(this.dataParams)
      }, error: (err) => {
        console.error(err);
      }
    });
  }
  initServices() {
    this.subscribeWebSocketConnected();
  }

  private subscribeWebSocketConnected(): Subscription {
    return this.webSocketService.getTokenEvent().subscribe(() => {
      setTimeout(() => {
        this.isLoading = false;
        if (this.tenantData.data.personaDigital.interfazUsuario.pantallaInicial.botonInicio.texto === '') this.avoidInitButtonSubject.next();
      }, 1000);
    });
  }

  private subscribeShortenUrl(id: string) {
    this.ShortenUrlService.getShortenUrl(id).subscribe({
      next: (res: UrlParams) => {
        this.startAppWithResParams(res);
      }, error: (err) => {
        console.error(err);
        this.handleLinkError();
      }
    });
  }

  private startAppWithResParams(res: UrlParams) {
    this.fillDataParams(res);
    this.startAppWithParams();
    this.initServices()
  }

  private fillDataParams(res: UrlParams) {
    this.dataParams = res;
    this.dataParams.CasoDeUso = this.dataParams.CasoDeUso.replace(/%20?/g, ' ');
    this.dataParams.Flujo = this.dataParams.Flujo.replace(/%20?/g, ' ');
  }

  private handleLinkError() {
    this.toastr.error('El enlace no es válido, verifique que sea el correcto.', 'Enlace inválido', {
      timeOut: 7000,
      progressBar: true,
      progressAnimation: 'increasing',
      positionClass: 'toast-top-right',
      closeButton: true,
    });

    this.startAppWithDefaultParams();
  }


  private subscribeUnavailableServiceModals() {
    this.subscribeServiceUnavailable();
    this.subscribeMaxSessions();
    this.subscribeCheckActiveSubscriptions(this.dataParams);
  }

  private subscribeServiceUnavailable() {
    this.webSocketService.getServiceUnavailableEvent().subscribe({
      next: () => {
        this.openModalServiceUnavailable();
      }, error: (err) => {
        console.error(err);
      }
    });
  }

  private subscribeCheckActiveSubscriptions(dataParams: UrlParams) {
    this.getActiveSubscriptionsService.checkUnavailableService(dataParams.Tenant).subscribe({
      next: () => this.openModalServiceUnavailable()
    });
  }

  openModalServiceUnavailable() {
    this.serviceUnavailable = true;

    this.modalServiceUnavailable = this.modalService.show(ServiceUnavailableComponent, {
      class: 'modal-dialog-centered modal-xl',
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

  private subscribeMaxSessions() {
    this.webSocketService.getMaxSessionsState().subscribe({
      next: () => {
        this.openModalMaxSessions();
      }, error: (err) => {
        console.error(err);
      }
    });
  }

  openModalMaxSessions() {
    this.modalMaxSessions = this.modalService.show(MaxSessionsComponent, {
      class: 'modal-dialog-centered modal-xl',
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

  skipInitialView() {
    this.startPressedSubject.next();
  }

  skipInitialViewBuja(data: DatumButtons) {
    this.startPressedSubject.next(data)
  }

  configureFont(): string{
    const font = this.tenantData.data.personaDigital.interfazUsuario.fuente;
    if (!font.url || !font.url.includes('https')) return font.nombre;

    const format = this.getFontFormat(font.url);
    if (!format) return font.nombre;

    const style = document.createElement('style');
    style.textContent = `
    @font-face {
      font-family: 'Custom';
      src: url(${font.url}) format(${format});
    }`;

    document.head.appendChild(style);

    return 'Custom';
  }

  private getFontFormat(url: string): string | null {
    const extension = url.split('.').pop()?.toLowerCase();

    switch (extension) {
        case 'otf': return 'opentype';
        case 'ttf': return 'truetype';
        case 'woff': return 'woff';
        case 'woff2': return 'woff2';
        default: return null;
    }
  }

  onFocus = (): void => {
    window.parent.postMessage('pronto_abrir', '*');
  };
}
